import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PrivacyPage() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Navbar />

      <section id="hero" style={{ paddingTop: "60px" }}>
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-center align-items-center">
            <div className="col-lg-12 pt-lg-0 d-flex align-items-center justify-content-center">
              <div data-aos="zoom-out">
                <h1 className="fs-1">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2 d-flex align-items-center justify-content-center">
              <div data-aos="zoom-out">
                <h3 className="mb-3">Privacy Policy</h3>
                <p
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.6" }}
                >
                  DataAlp does not save any datasets uploaded to its website,
                  nor saves any data/reports created from the uploaded datasets.
                  To keep this startup as environment friendly as possible, a
                  database has not been linked to the website at the backend.
                  All the uploaded datasets are read as pandas dataframe and
                  then the dataframe is passed down to the algorithms to turn
                  your dataset in to a story. All this while all the pdfs
                  objects are created in memory stream and merged together as a
                  memory file, which is then emailed to you and the memory
                  stream/files are closed to keep no record of the created
                  files. For supervised learning datasets, a trained model is
                  created to make predictions, if a dataset for predictions is
                  provided. The trained models are also not saved anywhere and
                  can only be used if the predictions dataset is submmitted
                  initially with the training dataset. Later there will be an
                  option to download the model, to allow the user to get
                  predictions out of it, multiple times.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}
